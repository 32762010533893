import { Popover, Transition } from "@headlessui/react";
import {
  ArrowDownCircleIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { searchProductsRequest } from "../api";
import { LoadingIndicator } from "../components";
import {
  classNames,
  EImageSize,
  onProductImagesDownload,
  onSingleProductImageDownload,
} from "../shared";
import { ELoadedLevel, TProduct } from "../types";

const cdnUrl = import.meta.env.VITE_CDN_URL;
import { useRecoilValue } from "recoil";
import { userAtom } from "../state/atoms/userAtom";

export const AdminProductPage = () => {
  const [selectedCategory, setSelectedCategory] = useState("Lip");
  const user = useRecoilValue(userAtom);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [products, setProducts] = useState<TProduct[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState("fid");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const navigate = useNavigate();

  useEffect(() => {
    const loadProducts = async () => {
      try {
        const longLoad = setTimeout(() => setLoading(true), 300);
        const data = await searchProductsRequest(
          searchQuery,
          selectedCategory,
          pageSize,
          pageNumber,
          [],
          sortBy,
          sortOrder
        );
        clearTimeout(longLoad);

        setProducts(
          data.products.map((product) => ({
            ...product,
            imageswatch: product.imageswatch
              ? product.imageswatch.replace(".png", ".webp")
              : "",
            imagefront: product.imagefront
              ? product.imagefront.replace(".png", ".webp")
              : "",
            imagebrush: product.imagebrush
              ? product.imagebrush.replace(".png", ".webp")
              : "",
            imageside: product.imageside
              ? product.imageside.replace(".png", ".webp")
              : "",
            imagepackaging: product.imagepackaging
              ? product.imagepackaging.replace(".png", ".webp")
              : "",
            imagetop: product.imagetop
              ? product.imagetop.replace(".png", ".webp")
              : "",
          }))
        );
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    if (user?.status === ELoadedLevel.LOADED && !user?.user) {
      alert("Bitte Logge dich ein um diese Seite zu sehen!");

      return navigate("/de/login");
    }
    if (user?.status === ELoadedLevel.LOADED) {
      loadProducts().catch((error) => console.log(error));
    }
  }, [
    selectedCategory,
    pageSize,
    pageNumber,
    searchQuery,
    sortBy,
    sortOrder,
    user,
  ]);

  const previous = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const next = () => {
    setPageNumber(pageNumber + 1);
  };

  return (
    <div className="absolute left-0 w-full rounded-xl bg-white px-4 py-4 sm:px-6 lg:px-8">
      <div className="flex w-full justify-between sm:flex sm:items-center">
        <input
          type="text"
          name="search"
          id="search"
          className=" w-30 mt-4  block rounded-md border border-subtle-grey px-3 py-4 shadow-sm sm:text-sm"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search"
        />
        <select
          onChange={(e) => setSelectedCategory(e.target.value)}
          id="location"
          name="location"
          className="mt-4 block cursor-pointer  rounded-md border border-subtle-grey  px-3  py-4 pl-3 pr-10 text-base sm:text-sm"
          defaultValue={selectedCategory}>
          <option>Lip</option>
          <option>Nail</option>
        </select>

        <button
          type="button"
          onClick={previous}
          className="hover:bg-gray-50 relative mt-4 inline-flex items-center rounded-l-md border border-subtle-grey bg-white px-2 py-4 text-sm font-medium text-black focus:z-10 focus:outline-none focus:ring-1">
          <span className="sr-only">Previous</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <div>
          <label
            htmlFor="pageSize"
            className="block text-sm font-medium text-black">
            PageSize
          </label>
          <input
            type="text"
            name="pageSize"
            id="pageSizeInput"
            className=" block w-full rounded-md border border-subtle-grey px-3 py-4 shadow-sm sm:text-sm"
            value={pageSize}
            onChange={(e) => setPageSize(parseInt(e.target.value))}
            placeholder="100"
          />
        </div>

        <div>
          <label
            htmlFor="pageNumber"
            className="block text-sm font-medium text-black">
            PageNumber
          </label>
          <input
            type="text"
            name="pageNumber"
            id="pageNumber"
            className=" block w-full rounded-md border border-subtle-grey px-3 py-4 shadow-sm sm:text-sm"
            placeholder="1"
            value={pageNumber}
            onChange={(e) => setPageNumber(parseInt(e.target.value))}
          />
        </div>
        <button
          type="button"
          onClick={next}
          className="hover:bg-gray-50 relative -ml-px mt-4 inline-flex items-center rounded-r-md border border-subtle-grey bg-white px-2 py-4 text-sm font-medium text-black focus:z-10 focus:outline-none focus:ring-1">
          <span className="sr-only">Next</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <Link to="/de/9999/more-settings">
          <button
            type="button"
            onClick={previous}
            className="hover:bg-gray-50 relative mt-4 inline-flex items-center rounded-md border border-subtle-grey bg-white px-2 py-4 text-sm font-medium text-black focus:z-10 focus:outline-none focus:ring-1">
            More Settings
          </button>
        </Link>
      </div>
      <div className="-mx-4 mt-8 w-full overflow-visible shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
        {!loading ? (
          <table className="divide-gray-300 min-w-full divide-y overflow-x-scroll">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="text-gray-900 py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6">
                  FID{" "}
                  {sortOrder == "asc" ? (
                    <button
                      className="mt-2  pt-4"
                      onClick={() => {
                        setSortBy("fid");
                        setSortOrder("desc");
                      }}>
                      <ChevronDownIcon className="h-5 w-5 text-subtle-grey" />
                    </button>
                  ) : (
                    <button
                      className="mt-2  pt-4"
                      onClick={() => {
                        setSortBy("fid");
                        setSortOrder("asc");
                      }}>
                      <ChevronUpIcon className="h-5 w-5 text-subtle-grey" />
                    </button>
                  )}
                </th>
                <th
                  scope="col"
                  className="text-gray-900 hidden px-3 py-3.5 text-left text-sm font-semibold lg:table-cell">
                  GTIN/ NAME
                </th>
                <th
                  scope="col"
                  className="text-gray-900 hidden px-3 py-3.5 text-left text-sm font-semibold lg:table-cell">
                  Brand
                </th>
                <th
                  scope="col"
                  className="text-gray-900 hidden px-3 py-3.5 text-left text-sm font-semibold lg:table-cell">
                  Range
                </th>
                <th
                  scope="col"
                  className="text-gray-900 hidden px-3 py-3.5 text-left text-sm font-semibold lg:table-cell">
                  Variant
                </th>

                <th
                  scope="col"
                  className="text-gray-900 hidden px-3 py-3.5 text-left text-sm font-semibold sm:table-cell">
                  COLOR
                </th>
                <th
                  scope="col"
                  className="text-gray-900 px-3 py-3.5 text-left text-sm font-semibold">
                  SWATCH
                </th>
                <th
                  scope="col"
                  className="text-gray-900 px-3 py-3.5 text-left text-sm font-semibold">
                  IMAGE FRONT
                </th>
                <th
                  scope="col"
                  className="text-gray-900 px-3 py-3.5 text-left text-sm font-semibold">
                  IMAGE SIDE
                </th>
                <th
                  scope="col"
                  className="text-gray-900 px-3 py-3.5 text-left text-sm font-semibold">
                  IMAGE BRUSH
                </th>
                <th
                  scope="col"
                  className="text-gray-900 px-3 py-3.5 text-left text-sm font-semibold">
                  IMAGE Packaging
                </th>
                <th
                  scope="col"
                  className="text-gray-900 px-3 py-3.5 text-left text-sm font-semibold">
                  IMAGE TOP
                </th>

                <th
                  scope="col"
                  className="text-gray-900 px-3 py-3.5 text-left text-sm font-semibold">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="divide-gray-200 w-full divide-y bg-white">
              {products.map((product, index) => (
                <tr key={`${index}-${product.id}`}>
                  <td className="text-gray-900 w-full py-4 pl-4 pr-3 font-pangea-medium text-xs sm:w-auto sm:max-w-none sm:pl-6">
                    {product.fid}
                  </td>
                  <td className="text-gray-500 hidden px-3 py-4 text-xs lg:table-cell">
                    {product.gtin}
                  </td>
                  <td className="text-gray-500 hidden px-3 py-4 text-xs lg:table-cell">
                    {product.brand}
                  </td>
                  <td className="text-gray-500 hidden px-3 py-4 text-xs lg:table-cell">
                    {product.range1}
                  </td>
                  <td className="text-gray-500 hidden px-3 py-4 text-xs lg:table-cell">
                    {product.name}
                  </td>
                  <td className="text-gray-500 flex px-3 py-4 text-xs sm:table-cell ">
                    <div
                      className="h-10 w-10 rounded-full"
                      style={{ backgroundColor: product.colorhex }}></div>
                    {product.colorhex}
                  </td>
                  <td className="text-gray-500 group relative  px-3 py-4 text-xs sm:table-cell">
                    {product.imageswatch.length > 0 ? (
                      <>
                        <img
                          src={`${cdnUrl}/${product.imageswatch}`}
                          alt="imageswatch"
                          className="h-10 w-10"
                        />
                        <ImageDownloadOverlay
                          imageName={`Swatch_${product?.gtin}`}
                          url={product?.imageswatch}
                        />
                      </>
                    ) : null}
                  </td>
                  <td className="text-gray-500 group relative px-3 py-4 text-xs hover:cursor-pointer">
                    <img
                      src={`${cdnUrl}/${product?.imagefront}`}
                      alt={product?.name}
                      className="h-20 object-cover object-center"
                    />
                    <ImageDownloadOverlay
                      imageName={`Front_${product?.gtin}`}
                      url={product?.imagefront}
                    />
                  </td>
                  <td className="text-gray-500 group relative px-3 py-4 text-xs hover:cursor-pointer">
                    <img
                      src={`${cdnUrl}/${product?.imageside}`}
                      alt={product?.name}
                      className="h-20 object-cover object-center"
                    />
                    <ImageDownloadOverlay
                      imageName={`Side_${product?.gtin}`}
                      url={product?.imageside}
                    />
                  </td>
                  <td className="text-gray-500 group relative px-3 py-4 text-xs hover:cursor-pointer">
                    <img
                      src={`${cdnUrl}/${product?.imagebrush}`}
                      alt={product?.name}
                      className="h-20 object-cover object-center"
                    />
                    <ImageDownloadOverlay
                      imageName={`Brush_${product?.gtin}`}
                      url={product?.imagebrush}
                    />
                  </td>
                  <td className="text-gray-500 group relative px-3 py-4 text-xs hover:cursor-pointer">
                    <img
                      src={`${cdnUrl}/${product?.imagepackaging}`}
                      alt={product?.name}
                      className="h-20 object-cover object-center"
                    />
                    <ImageDownloadOverlay
                      imageName={`Packaging_${product?.gtin}`}
                      url={product?.imagepackaging}
                    />
                  </td>
                  <td className="text-gray-500 group relative px-3 py-4 text-xs hover:cursor-pointer">
                    <img
                      src={`${cdnUrl}/${product?.imagetop}`}
                      alt={product?.name}
                      className="h-20 object-cover object-center"
                    />
                    <ImageDownloadOverlay
                      imageName={`Top_${product?.gtin}`}
                      url={product?.imagetop}
                    />
                  </td>
                  <td className="text-gray-500 overflow-visible px-3 py-4 text-xs  ">
                    <Popover className="relative ">
                      {({ open }) => (
                        <>
                          <Popover.Button
                            className={classNames(
                              open ? "text-gray-900" : "text-gray-500",
                              "focus:ring-indigo-500 group inline-flex items-center rounded-md bg-white text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2"
                            )}>
                            <EllipsisVerticalIcon
                              className={classNames(
                                open ? "text-gray-600" : "text-gray-400",
                                "ml-2 h-5 w-5 hover:cursor-pointer"
                              )}
                            />
                          </Popover.Button>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1">
                            <Popover.Panel className="absolute left-0 z-20 mt-3 w-screen max-w-xs -translate-x-1/2 transform px-2 sm:px-0">
                              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                  <button
                                    key={1}
                                    onClick={() =>
                                      onProductImagesDownload(
                                        product,
                                        EImageSize.LARGE
                                      )
                                    }
                                    className="-m-3 block rounded-md p-3 transition duration-150 ease-in-out hover:bg-light-grey active:translate-y-1 active:scale-95 active:bg-subtle-grey">
                                    <p className="text-gray-900 text-base font-medium">
                                      Download all Images (Large)
                                    </p>
                                  </button>
                                  <button
                                    onClick={() =>
                                      onProductImagesDownload(
                                        product,
                                        EImageSize.SMALL
                                      )
                                    }
                                    key={2}
                                    className="-m-3 block rounded-md p-3 transition duration-150 ease-in-out hover:bg-light-grey active:translate-y-1 active:scale-95 active:bg-subtle-grey">
                                    <p className="text-gray-900 text-base font-medium">
                                      Download all Images (Small)
                                    </p>
                                  </button>
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <LoadingIndicator />
        )}{" "}
      </div>
    </div>
  );
};

type TImageDownloadOverlayProps = {
  url: string;
  imageName: string;
};

const ImageDownloadOverlay = ({
  url,
  imageName,
}: TImageDownloadOverlayProps) => {
  return (
    <div className="absolute top-0 right-0 hidden h-full w-full flex-col justify-around group-hover:flex">
      <DownloadImageButton
        label="Small"
        onClick={() =>
          onSingleProductImageDownload(url, imageName, EImageSize.SMALL)
        }
      />
      <DownloadImageButton
        label="Large"
        onClick={() =>
          onSingleProductImageDownload(url, imageName, EImageSize.LARGE)
        }
      />
    </div>
  );
};

type TDownloadImageButtonProps = {
  label: string;
  onClick: () => void;
};
const DownloadImageButton = ({ label, onClick }: TDownloadImageButtonProps) => {
  return (
    <button
      onClick={onClick}
      className="flex w-full rounded-xl bg-light-grey p-1 text-black hover:bg-white hover:opacity-100">
      <ArrowDownCircleIcon className=" h-7 w-7 text-subtle-grey " />
      {label}
    </button>
  );
};
