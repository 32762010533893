import { useRecoilStateLoadable, useRecoilValue } from "recoil";
import { tinyAdminAtom } from "../state/atoms/tinyAdminAtom";
import ToggleSwitch from "../components/switches/ToggleSwitch";
import { userAtom } from "../state/atoms/userAtom";
import { ELoadedLevel } from "../types";
import { useNavigate } from "react-router-dom";

export const MoreSettingsPage = () => {
  const [tinyAdminSettingsLoadable, setTinyAdminSettings] =
    useRecoilStateLoadable(tinyAdminAtom);
  const user = useRecoilValue(userAtom);
  const navigate = useNavigate();

  const isLoading = tinyAdminSettingsLoadable.state === "loading";

  if (isLoading || user?.status !== ELoadedLevel.LOADED) {
    return (
      <div className="mx-auto min-h-screen max-w-7xl px-4 py-4 sm:px-6 sm:py-6 lg:px-8 lg:py-8">
        Loading...
      </div>
    );
  }

  const changeAuthSettings = (enabled: boolean) => {
    setTinyAdminSettings(() => ({
      ...tinyAdminSettingsLoadable.contents,
      authModeOn: enabled,
    }));
  };

  if (user?.status === ELoadedLevel.LOADED && !user?.user) {
    alert("Bitte Logge dich ein um diese Seite zu sehen!");

    navigate("/de/login");
    return (
      <div className="mx-auto min-h-screen max-w-7xl px-4 py-4 sm:px-6 sm:py-6 lg:px-8 lg:py-8">
        Loading...
      </div>
    );
  }

  const tinyAdminSettings = tinyAdminSettingsLoadable.contents;

  return (
    <div
      id="main"
      className="mx-auto min-h-screen max-w-7xl px-4 py-4 sm:px-6 sm:py-6 lg:px-8 lg:py-8"
    >
      <h1 className="font-pangea-bold text-5xl">Tiny Admin Dashboard</h1>

      <div>
        <fieldset className="my-12 space-y-5">
          <div className="relative flex items-start">
            <div className="flex h-5 items-center">
              <ToggleSwitch
                testId="authMode-toggle"
                onToggled={changeAuthSettings}
                enabled={tinyAdminSettings?.authModeOn}
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="comments" className="text-gray-700 font-medium">
                Authentication
              </label>
              <p id="comments-description" className="text-gray-500">
                Wenn die Checkbox nicht gesetzt ist, ist lab und staging offen.
                Man braucht also keinen Account um sich lab anzuschauen. (Nur
                für testing zwecke öffnen)
              </p>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  );
};
