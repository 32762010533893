import { Switch } from "@headlessui/react";
import { classNames } from "../../shared";

type TToggleSwitchProps = {
  onToggled: (enabled: boolean) => void;
  enabled: boolean;
  testId?: string;
};

export default function ToggleSwitch({
  onToggled,
  enabled,
  testId,
}: TToggleSwitchProps) {
  return (
    <Switch
      data-test-id={testId}
      checked={enabled}
      onChange={onToggled}
      className={classNames(
        enabled ? "bg-purple" : "bg-light-grey",
        "border-transparent relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-purple transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-purple focus:ring-offset-2"
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? "translate-x-5" : "translate-x-0",
          "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
        )}
      />
    </Switch>
  );
}
