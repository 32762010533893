import { TProduct } from "../../types";

const cdnUrl = import.meta.env.VITE_CDN_URL;

type TDownloadableImages = {
  url: string;
  name: string;
};

export const downloadImages = (images: TDownloadableImages[]) => {
  images.forEach((image: TDownloadableImages) => {
    forceDownload(image.url, image.name);
  });
};

function forceDownload(url: string, fileName: string) {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.responseType = "blob";
  xhr.onload = function () {
    const urlCreator = window.URL || window.webkitURL;
    const imageUrl = urlCreator.createObjectURL(this.response);
    const tag = document.createElement("a");
    tag.href = imageUrl;
    tag.download = fileName;
    document.body.appendChild(tag);
    tag.click();
    document.body.removeChild(tag);
  };
  xhr.send();
}

export enum EImageSize {
  LARGE = "large",
  SMALL = "small",
}
export function onProductImagesDownload(
  product: TProduct,
  imageSize: EImageSize
) {
  const images =
    imageSize === EImageSize.LARGE
      ? [
          {
            url: `${cdnUrl}/${product?.imagebrush}`.replace(".webp", "_L.png"),
            name: `Brush_${product?.gtin}`,
          },
          {
            url: `${cdnUrl}/${product?.imagefront}`.replace(".webp", "_L.png"),
            name: `Front_${product?.gtin}`,
          },
          {
            url: `${cdnUrl}/${product?.imageside}`.replace(".webp", "_L.png"),
            name: `Side_${product?.gtin}`,
          },
          {
            url: `${cdnUrl}/${product?.imageswatch}`.replace(".webp", "_L.png"),
            name: `Swatch_${product?.gtin}`,
          },
          {
            url: `${cdnUrl}/${product?.imagepackaging}`.replace(
              ".webp",
              "_L.png"
            ),
            name: `Packaging_${product?.gtin}`,
          },
          {
            url: `${cdnUrl}/${product?.imagetop}`.replace(".webp", "_L.png"),
            name: `Top_${product?.gtin}`,
          },
        ]
      : [
          {
            url: `${cdnUrl}/${product?.imagebrush}`.replace(".webp", ".png"),
            name: `Brush_${product?.gtin}`,
          },
          {
            url: `${cdnUrl}/${product?.imagefront}`.replace(".webp", ".png"),
            name: `Front_${product?.gtin}`,
          },
          {
            url: `${cdnUrl}/${product?.imageside}`.replace(".webp", ".png"),
            name: `Side_${product?.gtin}`,
          },
          {
            url: `${cdnUrl}/${product?.imageswatch}`.replace(".webp", ".png"),
            name: `Swatch_${product?.gtin}`,
          },
          {
            url: `${cdnUrl}/${product?.imagepackaging}`.replace(
              ".webp",
              ".png"
            ),
            name: `Packaging_${product?.gtin}`,
          },
          {
            url: `${cdnUrl}/${product?.imagetop}`.replace(".webp", ".png"),
            name: `Top_${product?.gtin}`,
          },
        ];

  downloadImages(images);
}

export function onSingleProductImageDownload(
  url: string,
  imageName: string,
  imageSize: EImageSize
) {
  downloadImages(
    imageSize === EImageSize.LARGE
      ? [
          {
            url: `${cdnUrl}/${url}`.replace(".webp", "_L.png"),
            name: imageName,
          },
        ]
      : [
          {
            url: `${cdnUrl}/${url}`.replace(".webp", ".png"),
            name: imageName,
          },
        ]
  );
}
