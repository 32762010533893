import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { Footer } from "../components";
import { GlobalErrorDialog } from "../components/dialogs/GlobalErrorDialog";
import { LoadingPage } from "./LoadingPage";

import { RouteGuard } from "../components/RouteGuard";
import ScrollToTop from "../v2/components/helper/ScrollToTop";

export const Root = () => {
  return (
    <Suspense fallback={<LoadingPage />}>
      <RouteGuard>
        <ScrollToTop />
        <Outlet />
        <Footer />
        <GlobalErrorDialog />
      </RouteGuard>
    </Suspense>
  );
};
